/**
 * represent the basic navigation model
 */
import {NavItem, NavType} from './nav-item.model';
import {ProductType} from './shop/product-type.model';

export class Nav {
  headline = '';
  component = '';
  tree: NavItem[];


  constructor(obj: any = {}, cmsHost: string = '', vaccineConfig: any = {}) {
    this.headline = '';
    this.component = 'dashboard';
    this.tree = [];
    // this.tree = obj && obj.tree || [];
    // this.tree = [];

    // this.tree.push(new NavItem({navLinkName: 'NAVIGATION.DASHBOARD.LINK', path: 'dashboard'}));

    // this.tree.push(new NavItem({
    //   navLinkName: 'NAVIGATION.CHATBOT.LINK',
    //   // heading: 'NAVIGATION.CHATBOT.HEADING',
    //   showInNav: false,
    //   requiredAuthorities: [
    //     'GROUP_ADMIN',
    //     'GROUP_ACCOUNT_OWNER',
    //     'GROUP_SUBUSER'],
    // }))


    // this.tree.push(new NavItem({
    //   navLinkName: 'NAVIGATION.EXCLUSIVE.OFFERS.LINK',
    //   // heading: 'NAVIGATION.CHATBOT.HEADING',
    //   showInNav: false,
    //   requiredAuthorities: [
    //     'GROUP_ADMIN',
    //     'GROUP_ACCOUNT_OWNER',
    //     'GROUP_SUBUSER'],
    //   tree: [
    //     new NavItem({
    //       isTile: true,
    //       type: NavType.EXCLUSIVEOFFERS,
    //     })
    //   ]
    // }))

    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.DATA.LINK',
      requiredAuthorities: [
        'MY_SALES',
        'BULK_BILL_RECEIPT',
        'BULK_BILL',
        'CREDIT_NOTE',
        'VETERINARY_DRUG',
        'TRANSFUSION',
        'REIMPORT',
        'EXTENDED_BENEFIT',
        'SLIMFIT_EVALUATIONS'
      ],
      allowedDetails: [
        'sales',
        'invoices',
        'evaluations'
      ],
      tree: [
        new NavItem({
          heading: 'NAVIGATION.DATA.INVOICES.HEADING',
          navLinkName: 'NAVIGATION.DATA.INVOICES.LINK',
          component: 'belegsuche',
          path: 'belegsuche',
          requiredAuthorities: [
            'BULK_BILL_RECEIPT',
            'BULK_BILL',
            'CREDIT_NOTE',
            'VETERINARY_DRUG',
            'TRANSFUSION',
            'REIMPORT',
            'EXTENDED_BENEFIT'
          ],
          isTile: false,
          tileTitle: 'NAVIGATION.DATA.INVOICES.TILE',
          tileImageFileName: 'assets/img/tiles/svg/rechnung-abfragen-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/rechnung-abfragen-hover-kachel.svg',
          tilePosition: 10,
          allowedDetails: ['invoices']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.DATA.REVENUE.LINK',
          isTile: false,
          tileTitle: 'NAVIGATION.DATA.REVENUE.TILE',
          heading: 'NAVIGATION.DATA.REVENUE.HEADING',
          component: 'mein-umsatz',
          path: 'mein-umsatz',
          requiredAuthorities: ['MY_SALES'],
          tileImageFileName: 'assets/img/tiles/svg/umsatz-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/umsatz-hover-kachel.svg',
          tilePosition: 20,
          allowedDetails: ['sales']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.DATA.EVALUATION.LINK',
          isTile: false,
          tileTitle: 'NAVIGATION.DATA.EVALUATION.TILE',
          heading: 'NAVIGATION.DATA.EVALUATION.HEADING',
          component: 'evaluations',
          path: 'auswertungen',
          requiredAuthorities: ['SLIMFIT_EVALUATIONS'],
          tileImageFileName: 'assets/img/tiles/svg/evaluation-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/evaluation-hover-kachel.svg',
          pageStatus: this.getPageStatus(1, this.parseDate('2024-04-18T18:00:00')),
          tilePosition: 25,
          allowedDetails: ['evaluations']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.DATA.PAYBACK.LINK',
          isTile: false,
          tileTitle: 'NAVIGATION.DATA.PAYBACK.TILE',
          heading: 'NAVIGATION.DATA.PAYBACK.HEADING',
          component: 'payback',
          path: 'payback',
          tileImageFileName: 'assets/img/tiles/svg/payback-new-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/payback-new-hover-kachel.svg',
          tilePosition: 30
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.DATA.REKLAMATION.LINK',
          isTile: false,
          tileTitle: 'NAVIGATION.DATA.REKLAMATION.TILE',
          heading: 'NAVIGATION.DATA.REKLAMATION.HEADING',
          component: 'reklamation',
          path: 'reklamation',
          tileImageFileName: 'assets/img/tiles/svg/reklamation-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/reklamation-hover-kachel.svg',
          tilePosition: 40
        })
      ]
    }));


    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.ORDER.LINK',
      heading: 'NAVIGATION.ORDER.HEADING',
      activatesPath: ['bestellung'],
      dataCy: 'order-and-delivery',
      requiredAuthorities: [
        'ORDERS',
        'TRACK_TRACE'
      ],
      allowedDetails: [
        'orders',
        'trackTrace'
      ],
      tree: [
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.PRODUCTS.LINK',
          heading: 'NAVIGATION.ORDER.PRODUCTS.HEADING',
          component: 'doku-light',
          activatesPath: ['doku-light-details'],
          path: 'shop',
          isTile: false,
          tileTitle: 'NAVIGATION.ORDER.PRODUCTS.TILE',
          tileImageFileName: 'assets/img/tiles/svg/produktshop.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/produktshop-hover-kachel.svg',
          dataCy: 'doku-light',
          requiredAuthorities: ['ORDERS'],
          tilePosition: 2,
          allowedDetails: ['orders']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.LINK',
          heading: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.HEADING',
          component: 'nachlieferung',
          path: 'nachlieferung',
          isTile: false,
          tileTitle: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/nachlieferungliste-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/nachlieferungliste-hover-kachel.svg',
          requiredAuthorities: ['NACH'],
          tilePosition: 2,
          pageStatus: this.getPageStatus(2, this.parseDate('2023-10-18T18:00:00')),
            allowedDetails: ['nachlieferung']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.LINK',
          heading: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.HEADING',
          component: 'nachlieferung',
          path: 'nachlieferung',
          isTile: false,
          tileTitle: 'NAVIGATION.ORDER.NACHLIEFERUNGLISTE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/nachlieferungliste-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/nachlieferungliste-hover-kachel.svg',
          excludedAuthorities: ['E_SARE'],
          tilePosition: 2,
          type: NavType.DISABLED,
          showInNav: false,
          class: 'tile--inactive',
            excludedDetails: ['esare']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.VORMERKUNGEN.LINK',
          heading: 'NAVIGATION.ORDER.VORMERKUNGEN.HEADING',
          component: 'vormerkungen',
          path: ProductType.Vormerkungen,
          isTile: false,
          tileTitle: 'NAVIGATION.ORDER.VORMERKUNGEN.TILE',
          tileImageFileName: 'assets/img/tiles/svg/syringe-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/syringe-hover-kachel.svg',
          requiredAuthorities: ['ORDERS'],
          type: NavType.VACCINE,
          tilePosition: 3,
          showInNav: true,
          start: vaccineConfig.activeFrom,
          end: vaccineConfig.activeUntil,
          allowedDetails: ['orders']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.ZUTEILUNGSMENGE.LINK',
          heading: 'NAVIGATION.ORDER.ZUTEILUNGSMENGE.HEADING',
          component: 'zuteilungsmenge',
          path: 'zuteilungsmenge',
          isTile: false,
          tileTitle: 'NAVIGATION.ORDER.ZUTEILUNGSMENGE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/rechnung-abfragen-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/rechnung-abfragen-hover-kachel.svg',
          requiredAuthorities: ['ORDERS'],
          type: NavType.VACCINE,
          tilePosition: 4,
          start: '2021-03-29T00:00:00',
          allowedDetails: ['orders']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.LAGERAKTION.LINK',
          component: 'lageraktion',
          path: 'lageraktion/grippe2024',
          isTile: false,
          tileTitle: 'NAVIGATION.ORDER.LAGERAKTION.TILE',
          tileImageFileName: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel-hover.svg',
          requiredAuthorities: ['ORDERS'],
          type: NavType.VACCINE,
          tilePosition: 5,
          start: '2024-06-07T00:00:00',
          pageStatus: this.getPageStatus(1, this.parseDate('2024-06-13T17:59:00')),
          allowedDetails: ['orders']
        }),

                        // new NavItem({
                        //     navLinkName: 'NAVIGATION.ORDER.FLUSHOP.LINK',
                        //     component: 'flu-shop',
                        //     path: 'vorbestellung/lageraktion-grippe2024',
                        //     isTile: falsee,
                        //     tileTitle: 'NAVIGATION.ORDER.FLUSHOP.TILE',
                        //     tileImageFileName: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel.svg',
                        //     tileImageFileNameHover: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel-hover.svg',
                        //     requiredAuthorities: ['ORDERS'],
                        //     type: NavType.VACCINE,
                        //     tilePosition: 5,
                        //     start: '2022-06-27T00:00:00',
                        //     pageStatus: this.getPageStatus(1, this.parseDate('2023-02-14T17:59:00'))
                        // }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.FLUSHOPARCHIVE.LINK',
          heading: 'NAVIGATION.ORDER.FLUSHOPARCHIVE.HEADING',
          component: 'flu-shop-archive',
          path: 'vorbestellung/archive/grippe',
          isTile: false,
          tileTitle: 'NAVIGATION.ORDER.FLUSHOPARCHIVE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/vorbestellung_grippeimpfstoff_2022_kachel-hover.svg',
          requiredAuthorities: ['ORDERS'],
          type: NavType.VACCINE,
          tilePosition: 6,
          start: '2022-01-01T00:00:00',
          pageStatus: this.getPageStatus(9, this.parseDate('2024-10-31T23:59:00')),
          allowedDetails: ['orders']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.PROMO.LINK',
          heading: 'NAVIGATION.ORDER.PROMO.HEADING',
          component: 'promo',
          path: 'promotions',
          isTile: false,
          tileTitle: 'NAVIGATION.ORDER.PROMO.TILE',
          tileImageFileName: 'assets/img/tiles/svg/promotions-01.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/promotions-hover-01.svg',
          requiredAuthorities: ['ORDERS'],
          type: NavType.PROMO,
          tilePosition: 7,
          start: '2022-12-11T08:00:00',
          pageStatus: this.getPageStatus(2, this.parseDate('2022-03-04T23:59:00')),
          allowedDetails: ['orders']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.PARAGRAPH73.LINK',
          heading: 'NAVIGATION.ORDER.PARAGRAPH73.HEADING',
          component: 'paragraph73',
          path: ProductType.Paragraph73,
          isTile: false,
          tileTitle: 'NAVIGATION.ORDER.PARAGRAPH73.TILE',
          tileImageFileName: 'assets/img/tiles/svg/amg-73-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/amg-73-kachel-hover.svg',
          requiredAuthorities: ['ORDERS'],
          tilePosition: 8,
          allowedDetails: ['orders']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.UBERWEISER.LINK',
          heading: 'NAVIGATION.ORDER.UBERWEISER.HEADING',
          component: 'uberweiser',
          path: 'uberweiser',
          isTile: false,
          tileTitle: 'NAVIGATION.ORDER.UBERWEISER.TILE',
          tileImageFileName: 'assets/img/tiles/svg/uberweiser.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/uberweiser-hover.svg',
          requiredAuthorities: ['ORDERS'],
          type: NavType.UBER,
          tilePosition: 9,
          start: '2030-12-31T23:59:59',
            allowedDetails: ['orders']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.TOPPRISMA.LINK',
          heading: 'NAVIGATION.ORDER.TOPPRISMA.HEADING',
          component: ProductType.TopPrisma,
          path: ProductType.TopPrisma,
          isTile: false,
          tileTitle: 'NAVIGATION.ORDER.TOPPRISMA.TILE',
          tileImageFileName: 'assets/img/tiles/svg/prisma-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/prisma-hover-kachel.svg',
          requiredAuthorities: ['ORDERS'],
          pageStatus: this.getPageStatus(1, this.parseDate('2022-05-05T18:00:00')),
          tilePosition: 12,
          allowedDetails: ['orders']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.TRACKANDTRACE.LINK',
          heading: 'NAVIGATION.ORDER.TRACKANDTRACE.HEADING',
          component: 'track-trace',
          path: 'track-trace',
          isTile: false,
          requiredAuthorities: ['TRACK_TRACE'],
          tileTitle: 'NAVIGATION.ORDER.TRACKANDTRACE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/track-and-trace.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/track-and-trace-hover.svg',
          tilePosition: 14,
          allowedDetails: ['trackTrace']
        }),
        new NavItem({
          navLinkName: 'NAVIGATION.ORDER.RETOUREN.LINK',
          heading: 'NAVIGATION.ORDER.RETOUREN.HEADING',
          component: 'retouren',
          path: 'retouren',
          isTile: false,
          requiredAuthorities: ['PRODUCT_RETURNS'],
          tileTitle: 'NAVIGATION.ORDER.RETOUREN.TILE',
          tileImageFileName: 'assets/img/tiles/svg/retourenavis.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/retourenavis-hover.svg',
          tilePosition: 15,

          pageStatus: this.getPageStatus(1, this.parseDate('2023-07-17T23:00:00')),
          allowedDetails: ['orders']
        })
      ]
    }));

    // Create a subtree for the Advertorials

    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.ORDER.ADVERTORIAL.LINK',
      showInNav: false,
      tree: [
        new NavItem({
          // navLinkName: 'NAVIGATION.ORDER.ADVERTORIAL.LINK',
          // heading: 'NAVIGATION.ORDER.ADVERTORIAL.HEADING',
          // activatesPath: ['advertorial/:link'],
          // component: 'advertorial',
          // path: '1apharma',
          isTile: true,
          // tileTitle: '1A Pharma',
          // tileImageFileName: '/1APharma_Januar_2_Super_Banner.jpg',
          // tilePosition: 1,
          type: NavType.ADVERTORIAL,
          // class: 'whiteTile advertorial',
          // start: '2023-10-16T21:00:00',
          // end: '2024-03-31T23:59:00',
          // showInNav: false,
          // pageStatus: this.getPageStatus(6, this.parseDate('2025-07-31T23:00:00')),
        })
      ]
    }));

    // Create a sub-tree for the service related links and tiles

    const serviceTree = [];

    serviceTree.push(
        new NavItem({
          navLinkName: 'NAVIGATION.SERVICE.LINDA.LINK',
          heading: 'NAVIGATION.SERVICE.LINDA.HEADING',
          component: 'linda',
          path: 'pkp',
          activatesPath: ['pkp'],
          isTile: true,
          tileTitle: 'NAVIGATION.SERVICE.LINDA.TILE',
          tileImageFileName: 'assets/img/tiles/svg/LINDA-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/LINDA-kachel.svg',
          shouldOpenInNewTab: false,
          tilePosition: 1
        }),

        new NavItem({
          type: NavType.PDF,
          navLinkName: 'NAVIGATION.SERVICE.PDF_SOLERO.LINK',
          path: 'https://cspappsacc.blob.core.windows.net/cms/files/2024/SOLERO Fact-sheet inkl. Verlinkung.pdf',
          isTile: true,
          tileTitle: 'NAVIGATION.SERVICE.PDF_SOLERO.TILE',
          tileImageFileName: 'assets/img/tiles/solero_logo_web Apothekenportal.jpg',
          tileImageFileNameHover: 'assets/img/tiles/solero_logo_web Apothekenportal.jpg',
          shouldOpenInNewTab: true,
          // pageStatus: this.getPageStatus(1, this.parseDate('2024-01-16T18:00:00')),
          start: '2024-12-09T01:00:00',
          end: '2024-12-31T22:00:00',
          tilePosition: 2
        }),
        new NavItem({
          type: NavType.PDF,
          navLinkName: 'NAVIGATION.SERVICE.PDF.LINK',
          path: 'https://cspappsacc.blob.core.windows.net/cms/files/2024/PHOENIX Marketing Jahresplan 2024.pdf',
          isTile: true,
          tileTitle: 'NAVIGATION.SERVICE.PDF.TILE',
          tileImageFileName: 'assets/img/tiles/svg/marketing-calendar-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/marketing-calendar-kachel.svg',
          shouldOpenInNewTab: true,
          // pageStatus: this.getPageStatus(1, this.parseDate('2024-11-16T18:00:00')),
          tilePosition: 3
        }),
        new NavItem({
          // requiredAuthorities: ['PAYBACK', 'GROUP_ADMIN', 'GROUP_INTERNAL_USER'],
          navLinkName: 'NAVIGATION.SERVICE.DEINEAPOTHEKESTORE.LINK',
          isTile: true,
          tileTitle: 'NAVIGATION.SERVICE.DEINEAPOTHEKESTORE.TILE',
          tileImageFileName: 'assets/img/tiles/svg/phoenixstore-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/phoenixstore-hover-kachel.svg',
          shouldOpenInNewTab: true,
          type: NavType.DEINEAPOTHEKESTORE,
          tilePosition: 20,
        }),
        // new NavItem({
        //     // requiredAuthorities: ['GROUP_ACCOUNT_OWNER', 'GROUP_ADMIN', 'GROUP_INTERNAL_USER'],
        //     navLinkName: 'NAVIGATION.SERVICE.NOTFALLDEPOT.LINK',
        //     isTile: true,
        //     tileTitle: 'NAVIGATION.SERVICE.NOTFALLDEPOT.TILE',
        //     tileImageFileName: 'assets/img/tiles/svg/notfalldepot-kachel.svg',
        //     tileImageFileNameHover: 'assets/img/tiles/svg/notfalldepot-hover-kachel.svg',
        //     shouldOpenInNewTab: true,
        //     type: NavType.NOTFALLDEPOT
        // }),
        new NavItem({
          requiredAuthorities: ['GROUP_ACCOUNT_OWNER', 'GROUP_ADMIN', 'GROUP_INTERNAL_USER'],
          navLinkName: 'NAVIGATION.SERVICE.PRIVATSORTIMENT.LINK',
          isTile: true,
          tileTitle: 'NAVIGATION.SERVICE.PRIVATSORTIMENT.TILE',
          tileImageFileName: 'assets/img/tiles/svg/genuss-kultur-kachel.svg',
          tileImageFileNameHover: 'assets/img/tiles/svg/genuss-kultur-hover-kachel.svg',
          shouldOpenInNewTab: true,
          type: NavType.PRIVATSORTIMENT,
          tilePosition: 30
        })
    );

    if (obj && obj.length) {
      obj.forEach((element) => {
        serviceTree.push(new NavItem({
          navLinkName: element.title,
          heading: element.title,
          extUrl: element.url,
          path: 'static-content',
          isTile: true,
          tileTitle: element.title,
          tileImageFileName: cmsHost + element['button-icon-normal'],
          tileImageFileNameHover: cmsHost + element['button-icon-hover'],
          pageStatus: this.getPageStatus(element['page-status'], element['page-status-until']),
          tilePosition: 5,
          class: (element.url).slice(1)
        }));
      });
    }

    this.tree.push(new NavItem({
      navLinkName: 'NAVIGATION.SERVICE.LINK',
      tree: serviceTree
    }));

    // this.tree.push(new NavItem({
    //   requiredAuthorities: ['CONTACT_PERSON'],
    //   navLinkName: 'NAVIGATION.SUPPORTS.LINK',
    //   heading: 'NAVIGATION.SUPPORTS.HEADING',
    //   component: 'fragenundantworten',
    //   path: 'fragenundantworten',
    // }));
    this.tree.push(new NavItem({
      requiredAuthorities: ['CONTACT_PERSON'],
      heading: 'NAVIGATION.MAIL.HEADING',
      component: 'postfach',
      path: 'postfach',
      type: NavType.QUICK
    }));
    // PXPPO-1801 payback configurator not necessary anymore, remove after confirmation
    /*        this.tree.push(new NavItem({
                requiredAuthorities: ['CONTACT_PERSON'],
                heading: 'NAVIGATION.ADVERTISING_MATERIALS_CONFIGURATOR.HEADING',
                component: 'payback-werbemittelkonfigurator',
                path: 'payback-werbemittelkonfigurator',
                type: NavType.HEADING_ONLY
            }));
            this.tree.push(new NavItem({
                requiredAuthorities: ['CONTACT_PERSON'],
                heading: 'NAVIGATION.ADVERTISING_MATERIALS_CONFIGURATOR.HEADING',
                component: 'payback-werbemittelkonfigurator',
                path: 'payback-werbemittelkonfigurator',
                type: NavType.HEADING_ONLY
            }));*/
  }

  getParent(currentlyActivePath: string, extUrl: string = null): string {
    if (!this.tree || currentlyActivePath === 'dashboard'
        || (currentlyActivePath === 'static-content' && extUrl == null)) {
      return null;
    }
    let ret = null;
    let done = false;
    if (currentlyActivePath.indexOf('static-content') !== -1 && extUrl !== null) {
      this.tree.forEach(navItem => {
        if (navItem.tree) {
          navItem.tree.forEach(subNavItem => {
            if (subNavItem.extUrl && subNavItem.extUrl.indexOf(extUrl) !== -1) {
              ret = navItem.navLinkName;
              done = true;
              return;
            }
          });
        }
        if (done) {
          return;
        }
      });
    } else {
      this.tree.forEach(navItem => {
        if (navItem.tree) {
          navItem.tree.forEach(subNavItem => {
            if (subNavItem.path === currentlyActivePath || subNavItem.activatesPath.indexOf(currentlyActivePath) !== -1) {
              ret = navItem.navLinkName;
              done = true;
              return;
            }
          });
        }
        if (done) {
          return;
        }
      });
    }
    return ret;
  }

  getProperty(currentlyActivePath: string, extUrl: string = null, property: string = 'heading'): string | boolean {
    if (!this.tree || currentlyActivePath === 'dashboard'
        || (currentlyActivePath === 'static-content' && extUrl == null)) {
      return false;
    }
    let ret: string | boolean = false;
    let done = false;
    if (currentlyActivePath.indexOf('static-content') !== -1 && extUrl !== null) {
      if (property === 'path') {
        property = 'extUrl';
      }
      this.tree.forEach(navItem => {
        if (navItem.extUrl && navItem.extUrl.indexOf(extUrl) !== -1 && navItem.hasOwnProperty(property)) {
          ret = navItem[property];
          done = true;
        } else if (navItem.tree) {
          navItem.tree.forEach(subNavItem => {
            if (subNavItem.extUrl && subNavItem.extUrl.indexOf(extUrl) !== -1 && subNavItem.hasOwnProperty(property)) {
              ret = subNavItem[property];
              done = true;
              return;
            }
          });
        }
        if (done) {
          return;
        }
      });
    } else {
      this.tree.forEach(navItem => {
        if ((navItem.path === currentlyActivePath || navItem.activatesPath.indexOf(currentlyActivePath) !== -1)
            && navItem.hasOwnProperty(property)) {
          ret = navItem[property];
          done = true;
        } else if (navItem.tree) {
          navItem.tree.forEach(subNavItem => {
            if ((subNavItem.path === currentlyActivePath || subNavItem.activatesPath.indexOf(currentlyActivePath) !== -1)
                && subNavItem.hasOwnProperty(property)) {
              ret = subNavItem[property];
              done = true;
              return;
            }
          });
        }
        if (done) {
          return;
        }
      });
    }
    return ret;
  }


  getAuthoritiesForRoute(route: string): string[] {
    if (!this.tree || route === '/dashboard') {
      return [];
    }
    let authorities: string[] = [];
    this.tree.forEach(navItem => {
      if (route.indexOf(navItem.path) !== -1) {
        authorities = navItem.hasOwnProperty('requiredAuthorities') ? navItem['requiredAuthorities'] : [];
      } else if (navItem.tree) {
        navItem.tree.forEach(subNavItem => {
          if (route.indexOf(subNavItem.path) !== -1) {
            authorities = subNavItem.hasOwnProperty('requiredAuthorities') ? subNavItem['requiredAuthorities'] : [];
          }
        });
      }
    });
    return authorities;
  }

  getDetailsForRoute(route: string): string[] {
    if (!this.tree || route === '/dashboard') {
      return [];
    }
    let details: string[] = [];
    console.log(route);
    this.tree.forEach(navItem => {
      if (route.indexOf(navItem.path) !== -1) {
        details = navItem.hasOwnProperty('allowedDetails') ? navItem['allowedDetails'] : [];
      } else if (navItem.tree) {
        navItem.tree.forEach(subNavItem => {
          if (route.indexOf(subNavItem.path) !== -1) {
            details = subNavItem.hasOwnProperty('allowedDetails') ? subNavItem['allowedDetails'] : [];
          }
        });
      }
    });
    return details;
  }

  getExcludedDetailsForRoute(route: string): string[] {
    if (!this.tree || route === '/dashboard') {
      return [];
    }
    let details: string[] = [];
    console.log(route);
    this.tree.forEach(navItem => {
      if (route.indexOf(navItem.path) !== -1) {
        details = navItem.hasOwnProperty('excludedDetails') ? navItem['excludedDetails'] : [];
      } else if (navItem.tree) {
        navItem.tree.forEach(subNavItem => {
          if (route.indexOf(subNavItem.path) !== -1) {
            details = subNavItem.hasOwnProperty('excludedDetails') ? subNavItem['excludedDetails'] : [];
          }
        });
      }
    });
    return details;
  }

  getFeatureForRoute(route: string): string {
    if (!this.tree || route === '/dashboard') {
      return '';
    }

    let feature = '';

    this.tree.forEach(navItem => {
      if (route.indexOf(navItem.path) !== -1) {
        feature = navItem.feature ? navItem.feature : '';
      } else if (navItem.tree) {
        navItem.tree.forEach(subNavItem => {
          if (route.indexOf(subNavItem.path) !== -1) {
            feature = subNavItem.feature ? subNavItem.feature : '';
          }
        });
      }
    });

    return feature;
  }

  getVisibleForDcArray(route: string): number[] {
    if (!this.tree || route === '/dashboard') {
      return [];
    }
    let visibility = [];

    this.tree.forEach(navItem => {
      if (route.indexOf(navItem.path) !== -1) {
        visibility = navItem.visibleForDc ? navItem.visibleForDc : [];
      } else if (navItem.tree) {
        navItem.tree.forEach(subNavItem => {
          if (route.indexOf(subNavItem.path) !== -1) {
            visibility = subNavItem.visibleForDc ? subNavItem.visibleForDc : [];
          }
        });
      }
    });

    return visibility;
  }

  getStartDateForRoute(route: string) {
    if (!this.tree || route === '/dashboard') {
      return '';
    }

    let startDate = '';

    this.tree.forEach(navItem => {
      if (route.indexOf(navItem.path) !== -1) {
        startDate = navItem.start ? navItem.start : '';
      } else if (navItem.tree) {
        navItem.tree.forEach(subNavItem => {
          if (route.indexOf(subNavItem.path) !== -1) {
            startDate = subNavItem.start ? subNavItem.start : '';
          }
        });
      }
    });

    return startDate;
  }

  getEndDateForRoute(route: string) {
    if (!this.tree || route === '/dashboard') {
      return '';
    }

    let endDate = '';

    this.tree.forEach(navItem => {
      if (route.indexOf(navItem.path) !== -1) {
        endDate = navItem.end ? navItem.end : '';
      } else if (navItem.tree) {
        navItem.tree.forEach(subNavItem => {
          if (route.indexOf(subNavItem.path) !== -1) {
            endDate = subNavItem.end ? subNavItem.end : '';
          }
        });
      }
    });

    return endDate;
  }

  getNodeByRoute(route: string) {
    let node = null;

    this.tree.forEach(navItem => {
      if (route.indexOf(navItem.path) !== -1) {
        node = navItem;
      } else if (navItem.tree) {
        navItem.tree.forEach(subNavItem => {
          if (route.indexOf(subNavItem.path) !== -1) {
            node = subNavItem;
          }
        });
      }
    });

    return node;
  }

  parseDate(string) {
    const time = new Date(string);
    return time.getTime() / 1000;
  }

  getPageStatus(pageStatus, until) {
    let status;
    const now = new Date();

    if (until < now.getTime() / 1000) {
      return 0;
    }

    switch (pageStatus) {
      case 1:
        status = 'neu';
        break;
      case 2:
        status = 'update';
        break;
      case 3:
        status = 'letzte chance';
        break;
      case 4:
        status = 'Letzte Chance Efluelda';
        break;
      case 5:
        status = 'Hier teilnehmen';
        break;
      case 6:
        status = 'Advertorial';
        break;
      case 7:
        status = 'Neue Inhalte';
        break;
      case 8:
        status = 'Gewinnspiel';
        break;
      case 9:
        status = 'Inkl. Liefertermine';
        break;
      default:
        status = null;
        break;
    }

    return status;
  }
}
