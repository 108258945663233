import * as fromUserActions from '../actions/user.actions';
import {Inject, Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {AuthService} from '../../services/api/auth.service';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {UserRestService} from '../../services/api/user.rest-service';
import {Adress} from '../../models/adress.model';
import {UserResponse} from '../../models/user-response.model';
import {VaccineRestService} from '../../services/api/vaccination-rest-service';

@Injectable()
export class UserEffects {

    /**
     * On LoadUserAction request User from gateway and store it via LoadUserCompleteAction
     * or catch the error via LoadUserFailAction
     *
     * @type {Observable<any[]>}
     */
    @Effect()
    loadUser$: Observable<Action> = this.actions$
        .pipe(ofType<fromUserActions.LoadUserAction>(fromUserActions.UserActionTypes.LOAD_USER))
        .pipe(map(action => action), switchMap(() =>
                this.authService.getUserResponseFromGateway().pipe(
                    switchMap(data => [
                        new fromUserActions.LoadUserCompleteAction(new UserResponse(data.returnObject)),
                        new fromUserActions.LoadDashAction(),
                        new fromUserActions.LoadNavAction()
                    ]),
                    catchError((error) => of(new fromUserActions.LoadUserFailAction(error))
                    )
                )
            )
        );

    @Effect()
    loadDash$: Observable<Action> = this.actions$
        .pipe(ofType<fromUserActions.LoadDashAction>(fromUserActions.UserActionTypes.LOAD_DASH))
        .pipe(map(action => action), switchMap(() =>
                this.vaccineRestService.getVaccineShopConfig().pipe(
                    switchMap(conf =>
                        this.userRestService.getStaticNav(this.CMS_URL).pipe(
                            map(data => {
                                return new fromUserActions.LoadDashCompleteAction({
                                    data: this.getObjectValues(data),
                                    url: this.CMS_URL,
                                    vaccineConfig: conf.returnObject
                                });
                                }
                            ),
                            catchError((error) =>of(new fromUserActions.LoadDashFailAction({
                                        data: error,vaccineConfig: conf.returnObject }
                                    )
                                )
                            )
                        )
                    )
                )
            )
        );

    @Effect()
    loadNav$: Observable<Action> = this.actions$
        .pipe(ofType<fromUserActions.LoadNavAction>(fromUserActions.UserActionTypes.LOAD_NAV))
        .pipe(map(action => action), switchMap(() =>
                this.vaccineRestService.getVaccineShopConfig().pipe(
                    switchMap(conf =>
                        this.userRestService.getStaticNav(this.CMS_URL).pipe(
                            map(data => {
                                return new fromUserActions.LoadNavCompleteAction({
                                    data: this.getObjectValues(data),
                                    url: this.CMS_URL,
                                    vaccineConfig: conf.returnObject
                                })
                                }
                            ),
                            catchError((error) =>
                                of(new fromUserActions.LoadNavFailAction({
                                            data: error, vaccineConfig: conf.returnObject
                                        }
                                    )
                                )
                            )
                        )
                    )
                )
            )
        );

    /**
     * On LoadUserIDFsAction request idfs from gateway and store them via LoadUserIDFsCompleteAction
     * or catch the error via LoadUserIDFsFailAction
     *
     * @type {Observable<any>}
     */
    @Effect()
    loadIDFs$: Observable<Action> = this.actions$
        .pipe(ofType<fromUserActions.LoadUserIDFsAction>(fromUserActions.UserActionTypes.LOAD_USER_IDFS))
        .pipe(map(action => action.payload), switchMap((context) =>
                this.userRestService.getIDFs(context).pipe(
                    map(data => new fromUserActions.LoadUserIDFsCompleteAction({
                        idfs: data.returnObject,
                        context: context
                    })),
                    catchError((error) => of(new fromUserActions.LoadUserIDFsFailAction(error)))
                )
            )
        );

    /**
     * On LoadUserMainIdfAction request the user's main IDF from gateway.
     *
     * @type {Observable<any>}
     */
    @Effect()
    loadMainIdf$: Observable<Action> = this.actions$.pipe(
        ofType<fromUserActions.LoadUserMainIdfAction>(
            fromUserActions.UserActionTypes.LOAD_USER_MAIN_IDF
        )).pipe(map(action => action), switchMap(() =>
            this.userRestService.getMainIdf().pipe(
                map(data => new fromUserActions.LoadUserMainIdfCompleteAction(data.returnObject)),
                catchError(error => of(new fromUserActions.LoadUserMainIdfFailAction(error))
                )
            )
        )
    );

    /**
     * On LoadUserVZsAction request idfs from gateway and store them via LoadUserVZsCompleteAction
     * or catch the error via LoadUserVZsFailAction
     *
     * @type {Observable<any>}
     */
    @Effect()
    loadVZs$: Observable<Action> = this.actions$
        .pipe(ofType<fromUserActions.LoadUserVZsAction>(fromUserActions.UserActionTypes.LOAD_USER_VZS))
        .pipe(map(action => action.payload), switchMap(idf =>
                this.userRestService.getVZs(idf).pipe(
                    map(data => new fromUserActions.LoadUserVZsCompleteAction(data.returnObject, idf)),
                    catchError((error) => of(new fromUserActions.LoadUserVZsFailAction(error)))
                )
            )
        );

    /**
     * On LoadUserVZsAction request idfs from gateway and store them via LoadUserVZsCompleteAction
     * or catch the error via LoadUserVZsFailAction
     *
     * @type {Observable<any>}
     */
    @Effect()
    loadShippingAdresses$: Observable<Action> = this.actions$
        .pipe(ofType<fromUserActions.LoadUserShippingAdressesAction>(fromUserActions.UserActionTypes.LOAD_USER_SA))
        .pipe(map(action => action), switchMap(() =>
                this.userRestService.getShippingAdresses().pipe(
                    map(data => {
                        const adresses = data.returnObject as Object[];
                        return new fromUserActions.LoadUserShippingAdressesCompleteAction(adresses.map((adress) => new Adress(adress)));
                    }),
                    catchError((error) => of(new fromUserActions.LoadUserShippingAdressesFailAction(error)))
                )
            )
        );

    @Effect()
    loadMainDistributionCenterAdress$: Observable<Action> = this.actions$.pipe(
        ofType<fromUserActions.GetMainDistributionCentersAdressAction>(
            fromUserActions.UserActionTypes.LOAD_USER_DISTRIBUTION_CENTER_ADRESS
        )).pipe(map(action => action), switchMap(() =>
            this.userRestService.getMainDistributionCentersAdress().pipe(
                map(data => new fromUserActions.GetMainDistributionCentersAdressCompleteAction(data.returnObject)),
                catchError((error) => of(new fromUserActions.GetMainDistributionCentersAdressFailAction(error)))
            )
        )
    );

    @Effect()
    loadAllDistributionCenterAdress$: Observable<Action> = this.actions$.pipe(
        ofType<fromUserActions.GetAllDistributionCentersAdressAction>(
            fromUserActions.UserActionTypes.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADRESS
        )).pipe(map(action => action), switchMap(() =>
            this.userRestService.getAllDistributionCentersAdress().pipe(
                map(data => new fromUserActions.GetAllDistributionCentersAdressCompleteAction(data.returnObject)),
                catchError((error) => of(new fromUserActions.GetAllDistributionCentersAdressFailAction(error)))
            )
        )
    );

    @Effect()
    loadAuthToken$: Observable<Action> = this.actions$.pipe(
        ofType<fromUserActions.LoadAuthTokenAction>(
            fromUserActions.UserActionTypes.LOAD_AUTH_TOKEN
        )).pipe(map(action => action), switchMap(() =>
            this.userRestService.getAuthToken().pipe(
                map(data => new fromUserActions.LoadAuthTokenCompleteAction(data.returnObject)),
                catchError(error => of(new fromUserActions.LoadAuthTokenFailAction(error))
                )
            )
        )
    );

    @Effect()
    loadAuthTokens$: Observable<Action> = this.actions$.pipe(
        ofType<fromUserActions.LoadAuthTokensAction>(
            fromUserActions.UserActionTypes.LOAD_AUTH_TOKENS
        )).pipe(map(action => action), switchMap(() =>
            this.userRestService.getAuthTokens().pipe(
                map(data => new fromUserActions.LoadAuthTokensCompleteAction(data.returnObject)),
                catchError(error => of(new fromUserActions.LoadAuthTokensFailAction(error))
                )
            )
        )
    );


    /**
     * @param {AuthService} authService
     * @param {UserRestService} userRestService
     * @param {Actions} actions$
     */
    constructor(
        private authService: AuthService,
        private userRestService: UserRestService,
        private actions$: Actions,
        private vaccineRestService: VaccineRestService,
        @Inject('CMS_URL') private CMS_URL: String
    ) {
    }


    getObjectValues(obj) {
        const values = Object.keys(obj).map(function (e) {
            return obj[e];
        });
        return values;
    }
}
