<ng-container *ngIf="discountLevel !== 9">
    <div [innerHTML]="content"></div>
    <div class="mt-s">
        <div class="row">
            <div class="col-md-7 align-items-center d-flex">
                <a href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Flyer-{{month}}-{{getDiscountLevel()}}.pdf"
                   target="_blank">Flyer PDF {{ getMonth() }}</a>
            </div>
            <div class="col-md align-items-center d-flex">
                <a class="download-button btn btn-primary btn-round"
                   href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Flyer-{{month}}-{{getDiscountLevel()}}.pdf"
                   target="_blank">
                    <span class="arrow-helper far fa-arrow-down"></span>
                </a>
            </div>
        </div>
    </div>

    <div class="mt-s" *ngIf="discountLevel !== 0">
        <div class="row">
            <div class="col-md-7 align-items-center d-flex">
                <a href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/{{month}}-{{getDiscountLevel()}}.csv"
                   target="_blank">Preise CSV {{ getMonth() }}</a>
            </div>
            <div class="col-md align-items-center d-flex">
                <a class="download-button btn btn-primary btn-round"
                   href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/{{month}}-{{getDiscountLevel()}}.csv"
                   target="_blank">
                    <span class="arrow-helper far fa-arrow-down"></span>
                </a>
            </div>
        </div>
    </div>
    <div>
        <h3 class="mt-xl">Plakate für Ihre Screens</h3>
        <div class="row mt-s mb-xxl">
            <div class="col-md-4">
                <div class="align-items-center d-flex">
                    <a href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Plakat-{{month}}-{{getDiscountLevel()}}-quer-1.jpg"
                       target="_blank">
                        <img src="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Plakat-{{month}}-{{getDiscountLevel()}}-quer-1.jpg" class="image-shadow">
                        <span>Plakat Quer {{ getMonth() }} Seite 1</span></a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="align-items-center d-flex">
                    <a href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Plakat-{{month}}-{{getDiscountLevel()}}-quer-2.jpg"
                       target="_blank">
                        <img src="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Plakat-{{month}}-{{getDiscountLevel()}}-quer-2.jpg"  class="image-shadow">
                        <span>Plakat Quer {{ getMonth() }} Seite 2</span></a>
                </div>
            </div>
<!--            <div class="col-md-4">-->
<!--                <div class="align-items-center d-flex">-->
<!--                    <a href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Plakat-{{month}}-{{getDiscountLevel()}}-quer-3.jpg"-->
<!--                       target="_blank">-->
<!--                        <img src="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Plakat-{{month}}-{{getDiscountLevel()}}-quer-3.jpg"  class="image-shadow">-->
<!--                        <span>Plakat Quer {{ getMonth() }} Seite 3</span></a>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="row mt-l mb-xl">
            <div class="col-md-4">
                <div class="align-items-center d-flex">
                    <a href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Plakat-{{month}}-{{getDiscountLevel()}}-hoch-1.jpg"
                       target="_blank">
                        <img src="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Plakat-{{month}}-{{getDiscountLevel()}}-hoch-1.jpg"  class="image-shadow">
                        <span>Plakat Hoch {{ getMonth() }} Seite 1</span></a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="align-items-center d-flex">
                    <a href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Plakat-{{month}}-{{getDiscountLevel()}}-hoch-2.jpg"
                       target="_blank">
                        <img src="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Plakat-{{month}}-{{getDiscountLevel()}}-hoch-2.jpg"  class="image-shadow">
                        <span>Plakat Hoch {{ getMonth() }} Seite 2</span></a>
                </div>
            </div>
<!--            <div class="col-md-4">-->
<!--                <div class="align-items-center d-flex">-->
<!--                    <a href="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Plakat-{{month}}-{{getDiscountLevel()}}-hoch-3.jpg"-->
<!--                       target="_blank">-->
<!--                        <img src="https://cspappsacc.blob.core.windows.net/pkp/digitale-monatsaktion/Plakat-{{month}}-{{getDiscountLevel()}}-hoch-3.jpg"  class="image-shadow">-->
<!--                        <span>Plakat Hoch {{ getMonth() }} Seite 3</span></a>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</ng-container>
