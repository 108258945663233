import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pkp-geburtstag',
  templateUrl: './pkp-geburtstag.component.html',
  styleUrls: ['./pkp-geburtstag.component.scss']
})
export class PkpGeburtstagComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
