<h2>Entdecken Sie hier unsere Geburtstagsaktionen!</h2>
<div  id="accordion" class="accordion-style-1 my-xl">
  <div class="card">
    <div class="card-header" id="headOne">
      <button class="btn btn-link btn-collapsible collapsed" type="button" data-toggle="collapse"
              data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
        <h4>LINDA Kooperation</h4>
      </button>
    </div>
    <div id="collapseOne" class="collapse" aria-labelledby="headOne" data-parent="#accordion">
      <div class="card-body">
        <h2 class="pb-s">
          Wie Sie auf verschiedene Wege Ihre Endkunden faszinieren!
        </h2>
        <p>
          Unsere spannende Monatsaktion sowie die Kundenzeitschriften bieten Ihnen die perfekte Gelegenheit, Ihre Endkunden gezielt anzusprechen. Steigern Sie den Umsatz in Ihrer Apotheke mit den Top-Produkten der Saison! LINDA, die bekannteste und vertrauenswürdigste Marke im deutschen Apothekenmarkt verschafft Ihnen eine beeindruckende Reichweite. Nutzen Sie die Werbematerialien effektiv und holen Sie sich hier Tipps zur optimalen Nutzung.
        </p>
        <div class="row no-gutters mb-s">
          <div class="col-md-6">
              <strong>Dekowürfel:</strong>
            <ul>
              <li>Platzierung in frequenzstarken Zonen nahe des Kundenlaufweges in der Apotheke.</li>
              <li>Einsatzmöglichkeiten: gestapelt oder einzeln platziert.</li>
            </ul>
            <div class="text-center">
              <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/Mockup_Würfel_Vorlage.png" alt="" class="linda-img-promo">
            </div>
          </div>

          <div class="col-md-6">
            <strong>Monatsflyer:</strong>
            <ul>
              <li>Verteilmöglichkeiten:</li>
                <ul>
                  <li>Auslage am HV</li>
                  <li>Aktive Mitgabe im Kundengespräch</li>
                  <li>Beilage in das Magazin „be healthy!“</li>
                </ul>
            </ul>
            <div class="text-center">
              <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/Mockup_Flyer_Vorlage.png"
                   class="linda-img-promo" alt="">
            </div>
          </div>
        </div>
        <div class="row no-gutters mb-s">
          <div class="col-md-6">
            <strong>Plakat:</strong><br>
            <ul><li>Platzierungsmöglichkeiten:</li>
                <ul>
                  <li>Im Schaufenster</li>
                  <li>Als Kundenstopper vor der Apotheke</li>
                </ul>
            </ul>
          <div class="text-center">
            <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/Mockup_Plakat_Vorlage.png"
                 class="linda-img-promo" alt="">
          </div>
          </div>
          <div class="col-md-6">
            <strong>„be healthy!“ Lifestyle Magazin:</strong><br>
            <ul>
              <li>Modernes Kundenbindungselement, fördert zusätzliche Kundenströme in der Apotheke.</li>
              <li>Inhalte:
                <ul>
                  <li>Saisonale Fachbeiträge</li>
                  <li>Beiträge von Apotheken</li>
                  <li>Hochwertige Gewinnspiele als Gesprächseinstieg mit Kunden</li>
                </ul>
              </li>
              <li>
                Zusatzexemplare:
                <ul>
                  <li><a class="linki" href="https://phoenix-store.net/vk/be-healthy-endverbrauchermagazin.html" target="_blank">Hier</a> vorbestellbar schon ab 0,27 € pro Stück</li>
                  <li>Noch günstigere Preise im neuen <a class="linki"  target="_blank" href="https://phoenix-store.net/vk/be-healthy-endverbrauchermagazin.html">Abomodell</a></li>
                </ul>
              </li>
            </ul>

          <div class="text-center">
            <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/Mockup_be healthy_04_24_Cover+DS.jpg" alt="">
          </div>
        </div>
          </div>
        <div class="row no-gutters mb-s">
          <div class="col-md-6">
            <strong>Mein Ratgeber Diabetes</strong>
            <ul>
              <li>Zielgruppe: Alle Altersgruppen, sowohl Betroffene als auch Angehörige</li>
              <li>Platzierung:
                <ul>
                  <li>Sichtbar im Kassenbereich</li>
                  <li>Aktive Mitgabe beim Verkauf von Diabetes-Produkten</li>
                </ul>
              </li>
              <li>Über den <a class="linki" href="https://phoenix-store.net/vk/" target="_blank">PHOENIX Store</a> jederzeit zusätzliche Exemplare vorbestellen</li>
            </ul>
          <div class="text-center">
            <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/Titelbild Ratgeber Diabetes 4 2024.jpg"
                 alt="" class="linda-img-promo">
          </div>
        </div>
        </div>

        <div id="bordered-text-teaser">
          <strong class="text-l">
            Geburtstagsangebot
          </strong>
          <p>
            Im Rahmen unseres Geburtstages haben Sie die Möglichkeit bis zum 31.03.2025 kostenfreie <strong>LINDA Partner Aufkleber</strong> und <strong>Regal-Highlighter</strong> zu bestellen.
          </p>
        </div>

        <div class="row no-gutters my-l image-teasers">
          <div class="col-md-6">
            <h2 class="pb-s">Linda Partner Aufkleber</h2>
            <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/Linda Partner Aufkleber.png" alt="" height="500">
            <a href="mailto:kooperation@phoenixgroup.eu?subject=Bestellung%20kostenfreie%20LINDA%20Partner%20Aufkleber&body=Hallo%20liebes%20PHOENIX-Team,%0A%0Aich%20w%C3%BCrde%20gerne%20kostenfrei%20LINDA-Partner%20Aufkleber%20bestellen.%0AAnzahl:%0AMeine%20IDF%20lautet:%0ASenden%20Sie%20die%20Aufkleber%20bitte%20an%20folgende%20Adresse:%0A%0A%0ABesten%20Dank%20und%20viele%20Gr%C3%BC%C3%9Fe">
              <button class="btn btn-primary">Hier kostenlos bestellen!</button>
            </a>
          </div>
          <div class="col-md-6">
            <h2 class="pb-s">Regal-Highlighter</h2>
            <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/Regalhighlighter.png" alt="" height="500px">
            <a href="mailto:kooperation@phoenixgroup.eu?subject=Bestellung%20kostenfreie%20Regal-Highlighter&body=Hallo%20liebes%20PHOENIX-Team,%0A%0Aich%20w%C3%BCrde%20gerne%20kostenfrei%20Regal-Highlighter%20bestellen.%0AAnzahl:%0AMeine%20IDF%20lautet:%0ASenden%20Sie%20die%20Highlighter%20bitte%20an%20folgende%20Adresse:%0A%0ABesten%20Dank%20und%20viele%20Gr%C3%BC%C3%9Fe">
              <button class="btn btn-primary">Hier kostenlos bestellen!</button>
            </a>
          </div>
        </div>
        </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headTwo">
      <button class="btn btn-link btn-collapsible collapsed" type="button" data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false" aria-controls="collapseTwo">
        <h4>AKADEMIE</h4>
      </button>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headTwo" data-parent="#accordion">
      <div class="card-body">
        <h2 class="pb-s">Jetzt Vorteil der PHOENIX AKADEMIE sichern!</h2>
        <p>
          Ob Seminare vor Ort, Inhouse-Schulungen oder digitale Fortbildungen – die PHOENIX AKADEMIE unterstützt Sie dabei, Ihre Qualifikationen aktuell zu halten und zu erweitern.        </p>
        <p>
          Wählen Sie aus einem breiten Angebotsspektrum das Passende für sich heraus und sichern Sie sich <strong>einmalig und nur bis zum 31.03.2025 unseren exklusiven 5€ Rabattgutschein.</strong>
        </p>
        <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/akademie.png" alt="" class="img-fluid">
        <a href="https://www.phoenixakademie.de/angebotskatalog/">
          <button class="btn btn-secondary">Zum Angebotskatalog</button>
        </a>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headThree">
      <button class="btn btn-link btn-collapsible collapsed" type="button" data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false" aria-controls="collapseThree">
        <h4>LIVSANE</h4>
      </button>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headThree" data-parent="#accordion">
      <div class="card-body">
        <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/livsane logo.png" alt="" class="img-fluid">
        <h2 class="pb-s pt-l">Die exklusive LIVSANE-Produktwelt in Ihrer Apotheke!</h2>
        <p>
          Von abenteuerlustigen Kids über unerschrockene Eltern bis hin zu smarten Senior<sup>*</sup>innen: Die Produktwelt von LIVSANE ist für alle da und doch ebenso individuell wie Ihre Kund<sup>*</sup>innen. Egal ob im Alltag, im Beruf oder in der Freizeit – LIVSANE Produkte richten sich an jedes Alter und unterstützen in jeder Lebenslage.
        </p>
        <p>
          Sie möchten das Sortiment Ihrer Apotheke um eine einzigartige Facette erweitern? Dann nutzen Sie unser exklusives Geburtstagsangebot – nur für Beauftragte!
        </p>
        <p>
          Vom <strong>07.01.25 bis 31.01.25</strong> erhalten Sie gleich drei <strong>LIVSANE PRODUKTE mit einem Rabatt von bis zu 60%.</strong>
        </p>
        <p>
          <strong>Bestellen</strong> Sie direkt über unseren <strong>Produktshop</strong>, um mit uns den 2-jährigen Geburtstag auf ganz besondere Weise zu feiern!
        </p>
        <a href="/#/shop?promo=2024-12-01_281">
          <button class="btn btn-secondary">Hier bestellen</button>
        </a>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headFour">
      <button class="btn btn-link btn-collapsible collapsed" type="button" data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false" aria-controls="collapseFour">
        <h4>PAYBACK</h4>
      </button>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headFour" data-parent="#accordion">
      <div class="card-body">
        <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/payback.png" class="img-fluid" alt="">
        <h2 class="pb-s pt-l">Unsere PAYBACK Geburtstagsaktion – Der perfekte Zeitpunkt, um mit dem bekanntesten Kundenbindungsprogramm voll durchzustarten!</h2>
        <p>
          Gemeinsam mit unserem Partner PAYBACK bieten wir Ihnen exklusive Vorteile, um Ihre Kundenbindung auf das nächste Level zu heben.
        </p>
        <p><b>Sichern Sie sich jetzt:</b></p>
        <ul>
          <li>
            <strong>Individuelle digitale Werbematerialien:</strong> Profitieren Sie von exklusiven und für Ihre Apotheke individualisierten digitalen Werbemitteln. Senden Sie dazu einfach eine E-Mail an <a class="linki" href="mailto:support-payback@phoenixgroup.eu">support-payback@phoenixgroup.eu</a> und fügen Sie Ihr Apothekenlogo als Bild bei. Nutzen Sie diese maßgeschneiderten Inhalte, um Ihre Kund*innen direkt über Ihre Social-Media-Kanäle oder die Screens in Ihrer Apotheke anzusprechen. <br>
            <strong>Auf Wunsch erhalten Sie zusätzlich kleine Werbevideos,</strong> die Sie flexibel in Ihre Marketingmaßnahmen integrieren können.
          </li>
          <li>
            <strong>PAYBACK Schulung:</strong> Seit kurzem steht Ihnen das PAYBACK Schulungscenter zur Verfügung. Vereinbaren Sie kostenfrei einen Termin mit einem PAYBACK Expert*innen und erfahren Sie, wie Sie das volle Potenzial von PAYBACK für Ihre Apotheke ausschöpfen können. Jetzt <a href="#/page/payback-schulungscenter">hier</a> Termin vereinbaren!
          </li>
        </ul>
        <p><b>Jetzt mitfeiern und profitieren!</b><br>
          Ergreifen Sie die Chance, Ihre Kundenbindung zu stärken und Ihren Erfolg zu steigern – mit den exklusiven Vorteilen des Beauftragtenprogramms und PAYBACK.
        </p>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headFive">
      <button class="btn btn-link btn-collapsible collapsed" type="button" data-toggle="collapse"
              data-target="#collapseFive"
              aria-expanded="false" aria-controls="collapseFive">
        <h4>Gewinnspiel</h4>
      </button>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headFive" data-parent="#accordion">
      <div class="card-body">
        <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/Aveeno banner oben.jpg" alt="" class="img-fluid mb-m">
        <p>
          Gemeinsam mit unserem Partner <strong>Kenvue</strong> haben wir ein spannendes Gewinnspiel für Sie vorbereitet, bei dem Sie die Chance haben, eines von fünf <strong>hochwertigen Aveeno®-Produktpaketen</strong> zu gewinnen.
        </p>
        <div class="row no-gutters mb-s">
          <div class="col-md-6 aveeno-text">
            <p>
              Aveeno<sup>®</sup> Calm+ Restore Hafer Tagespflege, 50mlo <br>
              Aveeno<sup>®</sup> Daily Moisturising Body Lotion, 300ml<br>
              Aveeno<sup>®</sup> Dermexa Emollientien Creme, 200ml<br>
              Aveeno<sup>®</sup> Skin Relief Körperöl-Spray, 200ml<br>
              Aveeno<sup>®</sup> Skin Relief Body Lotion, 300ml<br>
              Aveeno<sup>®</sup> Notizbuch
            </p>
          </div>
          <div class="col-md-4">
            <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/aveeno produkte.png" class="img-fluid">
          </div>
        </div>

        <p>
          Alles, was Sie zur Teilnahme am Gewinnspiel tun müssen, ist uns ein Foto, welches für die starke Partnerschaft zwischen Ihnen und PHOENIX steht an folgende E-Mail-Adresse:  <a class="linki" href="mailto:kooperation@phoenixgroup.eu">kooperation@phoenixgroup.eu</a> zu schicken. Das Gewinnspiel findet vom 6. Januar 2025 bis zum 31. März 2025 statt. Die Gewinner werden im Anschluss ausgelost und zwei Wochen nach Aktionsende per E-Mail benachrichtigt.
        </p>
        <p>
          Kenvue bringt den weltweiten Experten für empfindliche Haut nach Deutschland: Endlich ist Aveeno auch in deutschen Apotheken erhältlich.
        </p>
        <p>
          Aveeno ist seit über 75 Jahren und mit mehr als 30 klinischen Studien an über 3.000 Patient:innen führend im Bereich der Haferforschung. Das umfangreiche Portfolio für Körper- und Gesichtspflege vereint das Beste aus Wissenschaft und Natur. Mit dem einzigartigen 3-fachen Haferkomplex sind Aveeno Produkte besonders wirksam für trockene, sehr trockene, empfindliche Haut!
        </p>
        <p>
          Weiterführende Informationen finden Sie auf <a href="https://aveeno.de/" class="linki" target="_blank">Aveeno<sup>®</sup> – Das Beste aus Wissenschaft und Natur</a>
        </p>
        <img src="https://cspappsacc.blob.core.windows.net/pkp/geburtstag/aveeno produkte2.png" class="img-fluid mb-m">
        <h4 class="mt-xl text-center small">Teilnahmebedingungen</h4>
        <p class="small">
          Ein Gewinnspiel, das Ihnen Spaß machen wird.  An unserer Verlosung können Sie ganz einfach durch die Übermittlung eines Bildes per E-Mail, welches für die starke Partnerschaft zwischen Ihnen und PHOENIX steht, teilnehmen. PHOENIX Pharmahandel GmbH & Co KG (im Folgenden PHOENIX) veranstaltet das Gewinnspiel in Kooperation mit der Johnson & Johnson GmbH. Das Gewinnspiel wird im Apothekenportal dargestellt als auch über ein Mailing das an Kooperationsbeauftragte geht und einen Newsletter beworben. Die Teilnahme erfolgt kostenfrei.
        </p>
        <h4 class="small">Was können Sie gewinnen?</h4>
        <p class="small">
          Im Zeitraum vom 06.01.2025 bis zum 31.03.2025 können Sie eines von insgesamt fünf Produktpaketen der Marke Aveeno<sup>®</sup>, bestehend aus sechs Produkten, gewinnen. Eine Barauszahlung des Gewinnes ist ausgeschlossen. Einem Teilnehmer/einer Teilnehmerin kann maximal ein Gewinn zugewiesen werden. Abgebildete Gewinne dienen zur Veranschaulichung und können von dem tatsächlichen Preis optisch abweichen.
        </p>
        <h4 class="small">Wer sind unsere Partner der Gewinnspiele? </h4>
        <p class="small">
          Dieser tolle Preis wird durch die Johnson & Johnson GmbH ermöglicht.
        </p>
        <h4 class="small">Wie funktioniert das Gewinnspiel?</h4>
        <p class="small">
          Eine Teilnahme ist ganz einfach und der Weg zu Ihrem Preis ist leicht zu gehen. Voraussetzung für die Teilnahme, ist, dass Sie älter als 18 Jahre sind und an unserem Beauftragtenprogramm teilnehmen. Alles, was Sie zur Teilnahme am Gewinnspiel tun müssen, ist uns ein Bild, welches für die starke Partnerschaft zwischen Ihnen und PHOENIX steht auf folgende E-Mail-Adresse: kooperation@phoenixgroup.eu zu schicken. Durch die Teilnahme am Gewinnspiel per E-Mail, akzeptieren Sie zudem die Teilnahmebedingungen.
        </p>
        <p class="small">Das Gewinnspiel läuft zwölf Wochen lang. </p>
        <h4 class="small">Wie erfolgt die Gewinnermittlung ?</h4>
        <p class="small">
          Die Gewinnziehung erfolgt über das Losverfahren. Die Gewinnerin/der Gewinner wird innerhalb von zwei Wochen nach Ende der Laufzeit des Gewinnspieles per E-Mail kontaktiert. Eine Abfrage der Kontaktdaten (Anschrift) findet im Anschluss statt. Wenn keine Einsendung der Informationen innerhalb von zwei Wochen erfolgt, so ist die PHOENIX dazu berechtigt, der Gewinnerin/dem Gewinner eine Frist für die Zusendung des Gewinnes zu setzen. Erfolgt die Übersendung weiterhin nicht, verfällt der Anspruch auf den Gewinn. Die Gewinnübermittlung erfolgt nur an deutsche Zustelladressen. Wenn der Gewinn nicht zustellbar ist, besteht seitens der PHOENIX keine Pflicht, Nachforschung anzustellen. Auch in diesem Fall entfällt der Anspruch auf den Gewinn.
        </p>
        <h4 class="small">Wann ist eine Teilnahme am Gewinnspiel leider nicht möglich? </h4>
        <p class="small">
          Mit der Teilnahme am Gewinnspiel erklärt sich die Teilnehmerin/der Teilnehmer mit den Teilnahmebedingungen einverstanden. Teilnahmeberechtigt ist jede natürliche, voll geschäftsfähige Person mit Wohnort in Deutschland ab 18 Jahren, ausgenommen Mitarbeiter der PHOENIX Pharmahandel GmbH & Co KG. Pro Person ist nur eine einmalige Teilnahme gestattet.
        </p>
        <p class="small">
          Die Beiträge (Bilder, Links und Texte) eines Teilnehmers dürfen keine Beleidigungen, falsche Tatsachen, Wettbewerbs-, Marken oder Urheberrechtsverstöße enthalten. Die Teilnehmerin/der Teilnehmer versichert, dass er über alle Rechte an möglicherweise eingereichten Bildern verfügt, die uneingeschränkten Verwertungsrechte aller Bildteile besitzt und, dass die Bilder frei von Rechten Dritter sind.
          PHOENIX Pharmahandel GmbH & Co KG behält sich das Recht vor, Teilnehmer:innen von der Teilnahme am Wettbewerb auszuschließen, sollten möglicherweise eingereichte Fotos gegen geltendes Recht oder den guten Geschmack verstoßen. Dies gilt ebenfalls bei Verstößen gegen die Teilnahmebedingungen und Versuchen, den Wettbewerb zu manipulieren.
        </p>
        <h4 class="small">Haftungsausschluss</h4>
        <p class="small">
          PHOENIX Pharmahandel GmbH & Co KG haftet nicht für technische Störungen, die bei der Datenübertragung auftreten. Ebenfalls erfolgt keine Haftung bei Unzustellbarkeit.
        </p>
        <h4 class="small">Datenschutzhinweise</h4>
        <p class="small">
          Um an dem Gewinnspiel teilnehmen zu können, muss die Teilnehmerin/der Teilnehmer personenbezogene Daten übermitteln. Für die Teilnahme sind Anrede, Vorname, Nachname,  die E-Mailadresse erforderlich. Für die Benachrichtigung und Bereitstellung des Gewinns wird die Anschrift sowie E-Mailadresse benötigt.
        </p>
        <p class="small">
          Rechtsgrundlage für diese Verarbeitung personenbezogener Daten ist unseres berechtigten Interesses Art. 6 Abs.1 lit. f) DSGVO, die Erfüllung des Vertrages aus dem Gewinnspiel nach Art. 6 Abs.1 lit.b) DSGVO und soweit Fotografien der Gweinner  veröffentlicht werden Art.6 Abs.1 lit.a) DSGVO. Sofern wir Ihre Daten auf Grundlage eines berechtigten Interesses verarbeiten, können Sie dieser Verarbeitung entsprechend Art. 21 DSGVO aus Gründen, die sich aus Ihrer besonderen Situation heraus ergeben, mit Wirkung für die Zukunft jederzeit widersprechen: gewinnspiel@phoenixgroup.eu
        </p>
        <p class="small">
          Soweit die Gewinnerin/der gewinner Fotografien der eigenen Person (Selfie) und ggf. von weiteren  Personen einreicht  willigt sie/er  ein, dass Fotografien  sowie ggf. der Vor- und Nachname und der  Titel mit Berufsbezeichnung von der PHOENIX group (d.h. PHOENIX Pharmahandel GmbH & Co KG und mit dieser nach § 15 AktienG verbundenen Unternehmen) unentgeltlich  im Intranet der PHOENIX group sowie in der Mitarbeiter-App Speakap (nicht von externen Dritten abrufbar) veröffentlicht werden. Weitere Informationen zu dieser Einwilligung werden mit Gewinnbenachrichtigung übermittelt.
        </p>
        <p class="small">
          Soweit nicht eine Verpflichtung zur Einhaltung gesetzlicher Aufbewahrungsfristen besteht, werden nach der Beendigung des Gewinnspiels, der Benachrichtigung der Gewinner und der Übergabe der Preise alle im Zusammenhang mit dem Gewinnspiel bei uns gespeicherten personenbezogenen Daten gelöscht.
        </p>
        <p class="small">
          Soweit PHOENIX personenbezogene Daten von Ihnen verarbeitet, finden Sie die Ihnen als Betroffene/Betroffener zustehenden weiteren Rechte sowie die allgemeine Datenschutzerklärung für die allgemeine Nutzung der Internetseiten unter folgendem Link: <a href="https://www.phoenix-online.de/de/datenschutz" target="_blank" class="linki">https://www.phoenix-online.de/de/datenschutz</a>
        </p>
        <p class="small">
          Viel Glück und Erfolg wünscht
        </p>
        <p class="small">
          das Beauftragten-Team von PHOENIX Pharmahandel GmbH & Co KG sowie die Johnson & Johnson GmbH
        </p>
      </div>
    </div>
  </div>
</div>


<p>Liebe<span class="phx-corporate"><sup>*</sup>r</span> Beauftragte<span class="phx-corporate"><sup>*</sup>r</span>,</p>
<p>
  am 11. Januar greifen wir nicht nur den Tag des Dankeschöns auf, um Ihnen und Ihrem Team für Ihre Treue zu danken, sondern feiern auch gemeinsam den 2-jährigen Geburtstag unseres Beauftragtenprogramms am 1. Februar! 🎉
</p>
<p>
  Ein riesiges Dankeschön für Ihr Engagement und Ihre wertvolle Unterstützung. Ihr Einsatz ist unbezahlbar und trägt dazu bei, dass unsere Aktionen erfolgreich umgesetzt werden.
</p>
<p>
  Zur Feier dieses besonderen Meilensteins haben wir gemeinsam mit unseren Partnern exklusive Angebote für das erste Quartal vorbereitet. Entdecken Sie diese Aktionen über das Dropdown-Menü im oberen Bereich, darunter auch ein spannendes Gewinnspiel unseres Partners Kenvue.
</p>
<p>
  Zusätzlich haben wir Sie bereits Ende Dezember mit einer PHOENIX Mini-Wanne beschenkt, prall gefüllt mit nützlichen Alltagshelfern, um Sie auf die Geburtstagsaktionen im Januar einzustimmen. Wir hoffen, Sie haben sich darüber gefreut!
</p>
<p>
  Herzliche Grüße,<br>
  Ihr Beauftragten-Team
</p>

<p>
  Sie sind gespannt, was <strong>2025</strong> für Sie bereit hält?
</p>
<p>
  Das Video hält so einige Informationen dazu für Sie bereit!
</p>
<div class="text-center mt-l screen-background">
  <video controls class="embed-responsive-item" width="400">
    <source src="https://cspappsacc.blob.core.windows.net/video/RZ_Video_Video_Rück+Ausblick_2025_15MBs.mp4"
            type="video/mp4">
    Ihr Browser unterstützt leider keine eingebetteten Videos.
  </video>
</div>
